import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
    <a href="http://www.afsolutions.co.in">
    © 2021, Powered by A F Solutions</a>  V 2.0
    </span>
  `,
})
export class FooterComponent {
}
